import React from 'react';

const Sitemap=()=>{

	return(
		<div>
{/* <?xml version="1.0" encoding="UTF-8"?>			
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:news="http://www.google.com/schemas/sitemap-news/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml" xmlns:mobile="http://www.google.com/schemas/sitemap-mobile/1.0" xmlns:image="http://www.google.com/schemas/sitemap-image/1.1" xmlns:video="http://www.google.com/schemas/sitemap-video/1.1">
<url> <loc>https://www.thevivienbrand.com/</loc> </url>
<url> <loc>https://www.thevivienbrand.com/</loc> </url>
<url> <loc>https://www.thevivienbrand.com/about</loc> </url>
<url> <loc>https://www.thevivienbrand.com/contact</loc> </url>
<url> <loc>https://www.thevivienbrand.com/blog/about</loc> </url>
<url> <loc>https://www.thevivienbrand.com/blog/contact</loc> </url>
<url> <loc>https://www.thevivienbrand.com/blog/home</loc> </url>
<url> <loc>https://www.thevivienbrand.com/blog/post</loc> </url>
<url> <loc>https://www.thevivienbrand.com/blog/booking</loc> </url>
<url> <loc>https://www.thevivienbrand.com/blog/register</loc> </url>
<url> <loc>https://www.thevivienbrand.com/blog/login</loc> </url>
</urlset> */}
		</div>
	)
}
export default Sitemap;